.PromoItem {
  margin-bottom: 10px;
  display: flex;
  & figure {
    align-self: flex-start;
    margin-right: 10px;
    min-width: 64px;
  }
  &__text {
    flex: 1 1 auto;
  }
  .added {
    margin-top: auto;
  }
}

.ItemText {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image.is-64x64 {
  & img {
    background-color: black;
    border-radius: 5px;
  }
}
